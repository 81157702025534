//Count
export const FETCH_COUNT = "FETCH_COUNT";
export const SET_COUNT = "SET_COUNT";
export const SET_FETCH_COUNT =  "SET_FETCH_COUNT";
export const SET_AVATAR = "SET_AVATAR";
export const SET_AVATAR_ACCESSORY = "SET_AVATAR_ACCESSORY";
export const SET_AVATARS = "SET_AVATARS";
export const SET_DRESS_ACCESSORY = "SET_DRESS_ACCESSORY";
export const SET_CUSTOM_AVATAR =  "SET_CUSTOM_AVATAR";
export const SET_STORE_AVATARS = "SET_STORE_AVATARS";
export const SET_AVATAR_ACCESSORY_TEMP = "SET_AVATAR_ACCESSORY_TEMP";
export const SET_AVATAR_ACCESSORY_ITEM = "SET_AVATAR_ACCESSORY_ITEM";


//Login
export const LOGIN = "LOGIN";


//URL
export const URL_BASE = "http://banktest.musicmath.mx/api";
export const URL_LOGIN = `${URL_BASE}/login`;

export const URL_GET_COUNT = `${URL_BASE}/user/`;
export const URL_GET_AVATAR = `${URL_BASE}/avatar/`;
export const URL_POST_CHANGE = `${URL_BASE}/user/changeAvatar`;
export const URL_GET_STORE = `${URL_BASE}/store/`;
export const URL_BUY_ITEM = `${URL_BASE}/user/buy`;
export const URL_SAVE = `${URL_BASE}/user/save`;
