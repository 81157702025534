import React from 'react';
import { Container, Col, Row, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { FormControl, InputLabel, Input, Button, Grid, FormHelperText } from '@material-ui/core';
import {renderTextfield} from '../../util';
import { Field, reduxForm } from 'redux-form';

const LoginComponent = props => {
  const { handleSubmit, submitting, show, handleClose, msg } = props;
  return (
    <div>
      <Alert color="info" isOpen={show} toggle={handleClose}>
        {msg}
      </Alert>
      <Container className="background" fluid={true}>
        <Row className="title">
          <Col className="" lg={{size:12}} md={{size:12}} xs={{size:12}}>
            <h4 className="intro">Bienvenido</h4>
            <img className="logo" alt="logo" src="./../../image/logo.png" />
          </Col>
        </Row>
        <Row>
          <div style={{height:"50px"}}></div>
          <Col className="form_login" lg={{size:6}} md={{size:6}} xs={{size:8}}>
            
            <Row style={{margin:"50px auto"}}>
              <Col lg={{size:12}} md={{size:12}} xs={{size:12}}>
                <img className="cofre" src="./../../image/cofre/cofre.png" />
              </Col>
              <Col >
                <form onSubmit={handleSubmit}>
                  <Field 
                    id="user" name="user" type="text"
                    label="user"
                    component={renderTextfield}
                  />
                  <br/>
                  <br/>
                  <Field
                    style={{margin:"100%"}}
                    id="password" name="password" type="password"
                    label="password"
                    type="password"
                    component={renderTextfield}
                  />
                  <br/>
                  <br/>
                  <br/>
                  <Button id="loginButton" type="submit" disabled={submitting}>
                    <p className="botton_font">Entrar</p>
                  </Button>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default reduxForm({
  form: 'LoginComponent'
})(LoginComponent)