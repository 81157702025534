import {handleActions} from 'redux-actions';

import { SET_AVATAR, SET_AVATAR_ACCESSORY, SET_AVATARS, SET_AVATAR_ACCESSORY_TEMP, SET_AVATAR_ACCESSORY_ITEM } from './../constans';

export const avatar = handleActions({
  [SET_AVATAR]: (state, action) => {
    return action.payload;
  }
},
"0")

export const avatarAccessory = handleActions({
  [SET_AVATAR_ACCESSORY]: (state, action) => {
    return action.payload;
  }
},{
  "jewelry":"joyeriaAzul",
  "head":"pañueloCuello",
  "body":"camisaPlatano",
  "body_bottom":"short",
  "footer":"tenis"
});

export const avatarAccessoryTemp = handleActions({
  [SET_AVATAR_ACCESSORY_TEMP]: (state, action) => {
    console.log(action.payload);
    return {
      "jewelry":{
        file: action.payload.jewelry,
        name: action.payload.jewelry,
        accept: true
      },
      "head":{
        file: action.payload.head,
        name: action.payload.head,
        accept: true
      },
      "body":{
        file: action.payload.body,
        name: action.payload.body,
        accept: true
      },
      "body_bottom":{
        file: action.payload.body_bottom,
        name: action.payload.body_bottom,
        accept:true
      },
      "footer":{
        file: action.payload.footer,
        name: action.payload.footer,
        accept:true
      }
    };
  },
  [SET_AVATAR_ACCESSORY_ITEM]:(state, action) => {
    var accesorys = state;
    var item = action.payload;
    console.log(item);
    switch(item.category){
      case "jewelry": 
        accesorys.jewelry = {
          file: item.id,
          name: item.file,
          accept :item.bought
        }; break;
      case "head": 
        accesorys.head = {
          file: item.id,
          name: item.file,
          accept :item.bought
        }; break;
      case "body": 
        accesorys.body = {
          file: item.id,
          name: item.file,
          accept :item.bought
        }; break;
      case "body_bottom": 
        accesorys.body_bottom = {
          file: item.id,
          name: item.file,
          accept :item.bought
        }; break;
      case "footer": 
        accesorys.footer = {
          file: item.id,
          name: item.file,
          accept :item.bought
        }; break;
    }

    return accesorys;
  }
},{
  "jewelry":{
    file:"joyeriaAzul",
    name:"joyeriaAzul",
    accept: true
  },
  "head":{
    file:"pañueloCuello",
    name:"pañueloCuello",
    accept: true
  },
  "body":{
    file:"camisaPlatano",
    name:"camisaPlatano",
    accept: true
  },
  "body_bottom":{
    file:"short",
    name:"short",
    accept:true
  },
  "footer":{
    file:"tenis",
    name:"tenis",
    accept:true
  }
});

export const avatars = handleActions({
  [SET_AVATARS]: (state, action) => {
    return action.payload;
  }
},[
  "1",    
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12"
]);