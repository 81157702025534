import {createAction} from 'redux-actions';
import { SET_AVATAR, SET_AVATAR_ACCESSORY, SET_AVATAR_ACCESSORY_TEMP, SET_AVATAR_ACCESSORY_ITEM, SET_CUSTOM_AVATAR, SET_AVATARS, SET_STORE_AVATARS } from '../../constans';

export const setAvatar = createAction( SET_AVATAR, payload => payload );

export const setAvatarAccessory = createAction(SET_AVATAR_ACCESSORY, payload => payload);
export const setAvatarAccessoryTemp = createAction(SET_AVATAR_ACCESSORY_TEMP, payload => payload);
export const setAvatarAccessoryItem = createAction(SET_AVATAR_ACCESSORY_ITEM, payload => payload);

export const setAvatars = createAction( SET_AVATARS, payload => payload );

export const setStoreAvatars = createAction(SET_STORE_AVATARS, payload => payload);