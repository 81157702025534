import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setAvatars, setAvatar } from '../actions/avatar';
import { withRouter } from 'react-router-dom';
import { URL_GET_AVATAR, URL_POST_CHANGE } from '../constans';
import { getCount, getAvatar, getAvatarAccessory, getAvatars } from '../selectors/dashboard';
import { getLogin } from '../selectors/login';
import AvatarComponent from '../components/AvatarComponent';

class AvatarContainer extends Component {
  componentDidMount(){
    if(!this.props.login.accept){
      this.props.history.push('/');
    }
    else
    {
      this.getAvatar();
    }
  }

  getAvatar(){
    Axios({
      url: URL_GET_AVATAR + this.props.login.id,
      method: "GET",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then( (res) => {
      console.log(res.data)
      this.props.setAvatars(res.data.avatars);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  handleChangeAvatar = ( value ) => {
    console.log(value);
    this.props.setAvatar(value);
    Axios({
      url: URL_POST_CHANGE,
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      },
      data:{
        "id":this.props.login.id,
        "avatarId":Number(value)
      }
    })
    .then( (res) => {
      console.log(res.data)
      
    })
    .catch( err => {
      return Promise.reject(err);
    })
    this.props.history.push('/home');
  }

  render(){
    return (
      <AvatarComponent 
        name={this.props.login.student}
        avatars={this.props.avatars}
        avatarAccessory={this.props.avatarAccessory}
        handleChangeAvatar={this.handleChangeAvatar}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  login: getLogin(state),
  avatar: getAvatar(state),
  avatars: getAvatars(state),
  avatarAccessory: getAvatarAccessory(state)
});

export default ( 
  withRouter( 
    connect( mapStateToProps, { setAvatars, setAvatar } 
      ) (AvatarContainer) 
  )
);