import { handleActions } from 'redux-actions';

import { SET_DRESS_ACCESSORY } from '../constans';

export const store = handleActions({
  [SET_DRESS_ACCESSORY]: (state, action) => {
    return action.payload;
  }
}, [
   {
      file:"camisaLuna",
      category:"body",
      name:"camisaLuna",
      buyer:false
   },
   {
     file:"camisaPlatano",
     category:"body",
     name:"camisaPlatano",
     buyer:false
   },
   {
     file:"collar_jade.png",
     category:"jewelry",
     name:"collarJade",
     buyer:false
   },
   { 
     file:"joyeriaAmarillo",
     category:"jewelry",
     name:"joyeriaAmarillo",
     buyer:false
   },
   {
     file:"lentesArriba",
     category:"head",
     name:"lentesArriba",
     buyer:false
   },
   {
     file:"lentesOjos",
     category:"head",
     name:"lentesOjos",
     buyer:false
   },
   {
     file:"mono.png",
     category:"head",
     name:"moño",
     buyer:false
   },
   {
     file:"mono_azul.png",
     category:"head",
     name:"pañueloAzul",
     buyer:false
   },
   {
     file:"munequeras.png",
     category:"jewelry",
     name:"muñequeras",
     buyer:false
   },
   {
     file:"panueloCuello",
     category:"jewelry",
     name:"pañueloCuello",
     buyer:false
   },
   {
     file:"panueloAzul",
     category:"jewelry",
     name:"pañueloCuello",
     buyer:false
   },
   {
     file:"short.png",
     category:"body_bottom",
     name:"short",
     buyer:false
   },
   {
     file:"sombrero_cafe.png",
     category:"head",
     name:"SombreroCafe",
     buyer:false
   },
]);