import React, { Component } from 'react';
import { URL_GET_COUNT } from '../constans';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { setCount } from './../actions/dashboard';
import { setAvatar, setAvatarAccessory, setAvatarAccessoryTemp } from './../actions/avatar';
import { getCount, getAvatar, getAvatarAccessory } from './../selectors/dashboard';
import { getLogin } from './../selectors/login';
import DashboardComponent from '../components/DashboardComponent';

class DashboardContainer extends Component {

  componentDidMount(){
    if(!this.props.login.accept){
      this.props.history.push('/');
    }
    else
    {
      this.getCount();
    }
  }

  handleChangeAvatar = () => {
    this.props.history.push('/avatar');
  }

  handleGoShop = () => {
    this.props.history.push('/store');
  }

  getCount(){
    axios({
      url: URL_GET_COUNT + this.props.login.id,
      method: "GET",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then( (res) => {
      console.log(res.data);
      this.props.setAvatar(res.data.avatarId);
      this.props.setAvatarAccessory(res.data.avatarAccessory);
      this.props.setAvatarAccessoryTemp(res.data.avatarAccessory);
      this.props.setCount(res.data.bank);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  render() {
    return (
      <DashboardComponent 
        name={this.props.login.student}
        count={this.props.count}
        handleChangeAvatar={this.handleChangeAvatar}
        handleGoShop={this.handleGoShop}
        avatar={this.props.avatar}
        avatarAccessory={this.props.avatarAccessory}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  avatar: getAvatar(state),
  avatarAccessory: getAvatarAccessory(state),
  login: getLogin(state)
});

export default withRouter( connect( mapStateToProps, { setCount, setAvatar, setAvatarAccessoryTemp, setAvatarAccessory
}) (DashboardContainer) );