import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import { count } from './dashboard';
import { avatar, avatars, avatarAccessory, avatarAccessoryTemp } from './avatar';
import { store } from './store';
import { login } from './login';

export default combineReducers({
  form: reduxForm,
  count,
  avatar,
  avatars,
  avatarAccessory,
  avatarAccessoryTemp,
  store,
  login
})