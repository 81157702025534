import React, { Component } from 'react';
import { URL_LOGIN } from '../constans';
import LoginComponent from '../components/LoginComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../actions/login';
import { Alert } from 'reactstrap';

class LoginContainer extends Component {

  constructor(props){
    super(props);
    this.state = { show: false, msg: '' }
  }
  
  handleLogin = (values) => {
    const axios = require('axios').default;
    axios({
      url: URL_LOGIN,
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
        
      },
      data:{
        "user":values.user,
        "password":values.password
      }
    })
    .then( (res) => {
      if(res.data.success){
        console.log(res.data);
        this.props.login(res.data);
        this.props.history.push('/home');
      }
      else
      {
        console.log(res.data);
        this.setState({msg: res.data.error})
        this.handleClose();
      }
    })
    .catch( err => {
      
      return Promise.reject(err);
    })
  }

  handleClose(){
    var show = this.state.show
    this.setState(
      {show: !show}
    )
  }
  
  render() {
    return (
      <LoginComponent
        onSubmit={this.handleLogin}
        show = {this.state.show}
        msg = {this.state.msg}
        handleClose = {this.handleLogin}
      />
    );
  }
}

const mapStateToProps = (state) => ({

});

export default withRouter( connect( mapStateToProps, { login
}) (LoginContainer) );
