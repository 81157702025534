import { handleActions } from 'redux-actions';

import { SET_FETCH_COUNT } from './../constans';

export const count = handleActions({
  [SET_FETCH_COUNT]: (state, action) => {
    return {
      "banana_amarilla": action.payload.bananaYellow,
      "banana_naranja":  action.payload.bananaOrange,
      "banana_azul":    action.payload.bananaBlue,
      "totem_amarilla":  action.payload.totemYellow,
      "totem_azul":      action.payload.totemBlue,
      "totem_naranja":   action.payload.totemOrange
    }
  }
}, 
  {
    "banana_amarilla": "-",
    "banana_azul":     "-",
    "banana_naranja":  "-",
    "banana_negra":    "-",
    "banana_rosa":     "-",
    "banana_verde":    "-",
    "totem_amarilla":  "-",
    "totem_azul":      "-",
    "totem_naranja":   "-",
    "totem_negra":     "-",
    "totem_rosa":      "-",
    "totem_verde":     "-"
  }
)