import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormControl, InputLabel, Input, Button, Grid, FormHelperText } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const renderTextfield = ( props ) => {
  const { input, id, type, label, meta: { touched, error, visited }, ...custom } = props;
  return (<FormControl className="input" {...input} error={ error != undefined && visited == true ? true : false }>
    <InputLabel htmlFor="adornment-password">
      {label}
    </InputLabel>
    <Input
      id={id}
      type={type}
      component="input"
    />
    <FormHelperText id="component-error-text">{error != undefined && visited == true ? error : ''}</FormHelperText>
  </FormControl>)
}