import {handleActions} from 'redux-actions'

import { LOGIN } from './../constans';

export const login = handleActions({
  [LOGIN]: (state, action) => {
    return {
      "accept":true,
      "id":action.payload.id,
      "student":action.payload.student
    }
  }
},{
  "accept":false
})