import React from 'react';
import { Container, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormControl, InputLabel, Input, Button, Grid, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import '../../dist/customScroll.css';

const AvantarComponent = props => {
  const { name, avatars, avatar, avatarAccessory, handleChangeAvatar } = props;
  console.log(handleChangeAvatar);
  return (
    <div>
      <Container className="background" fluid={true}>
        <Row className="title">
          <Col lx={{size:12}} lg={{size:12}} md={{size:12}} xs={{size:12}}>
            <h4 className="intro">Bienvenido {name}</h4>
            <img className="logo" alt="logo" src="./../../image/logo.png" />
          </Col>
        </Row>
        <Row >
          <div style={{height:"20px"}}></div>
          <Col xs={{size:12}} sm={{size:12}} md={{size:12}} lx={{size:8, offset:1}} lg={{size:10, offset:1}}>
            <Row>
              <Col lg={{size:12}} md={{size:12}} xs={{size:12}}>
                <Row>
                  <CustomScroll heightRelativeToParent="700px">
                    {avatars.map( result => 
                        <Col lx={{size:4}} lg={{size:4}} md={{size:6}} sm={{size:6}} xs={{size:12}}>
                          <div className="avatarDiv">                            
                            <img className="avatar" src={"./../../image/avatars/monitos_"+ result.id +".png"} width="300"/>
                            { /*<img className="avatar_accesory" src={"./../../image/accesorios/"+ result +"/head/"+ avatarAccessory.footer + "_" + result + ".png"}  width="300"/>
                            
                            <img className="avatar_accesory" src={"./../../image/accesorios/"+ result +"/body/"+ avatarAccessory.body_bottom + "_" + result + ".png"}  width="300"/>
                            
                            <img className="avatar_accesory" src={"./../../image/accesorios/"+ result +"/footer/"+ avatarAccessory.jewelry + "_" + result + ".png"}  width="300"/>
                            <img className="avatar_accesory" src={"./../../image/accesorios/"+ result +"/footer/"+ avatarAccessory.head + "_" + result + ".png"}  width="300"/>
                            <img className="avatar_accesory" src={"./../../image/accesorios/"+ result +"/body_bottom/"+ avatarAccessory.body + "_" + result + ".png"}  width="300"/>*/}
                          </div>
                          <div style={{height:"20px"}}></div>
                          <Button id="avatarButton" onClick={() => handleChangeAvatar(result.id)}>
                            Cambiar avatar
                          </Button>
                        </Col>
                      )
                    }
                  </CustomScroll>  
                </Row>  
              </Col>
            </Row>
          </Col>
        </Row>  
      </Container>
    </div>
  );

};

export default AvantarComponent;