import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAvatarAccessory, setAvatarAccessoryTemp, setAvatarAccessoryItem } from '../actions/avatar';
import StoreComponent from '../components/StoreComponent';
import { setCount } from './../actions/dashboard';
import { setDressAccessory } from './../actions/store';
import { getCount, getAvatar, getAvatarAccessory, getAvatarAccessoryTemp } from './../selectors/dashboard';
import {getStore} from './../selectors/store';
import { getLogin } from '../selectors/login';
import { URL_GET_STORE, URL_BUY_ITEM, URL_SAVE } from '../constans';


class StoreContainer extends Component{
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, msg:'', show:false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  getStore(){
    Axios({
      url: URL_GET_STORE + this.props.login.id,
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( (res) => {
      console.log(res.data.items);
      this.props.setDressAccessory(res.data.items);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  componentDidMount() {
    if(!this.props.login.accept){
      this.props.history.push('/');
    }
    else
    {
      this.getStore();
    }
    
    this.props.setAvatarAccessoryTemp(this.props.avatarAccessory);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleChangeAvatar = ( value ) => {
    console.log(value);
    this.props.history.push('/home');
  }

  handleBuyAccessory = ( value ) => {
    Axios({
      url: URL_BUY_ITEM,
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      data:{
        "id":this.props.login.id,
        "itemId":value.id
      }
    })
    .then( (res) => {
      if(res.data.success){
        console.log(res.data);
        this.setState({msg:'Exito'});
        this.handleCloseAlert();
        this.getStore();
      }
      else
      {
        console.log(res.data);
        this.setState({msg: res.data.error});
        this.handleClose();
      }
    })
    .catch( err => {
      
      return Promise.reject(err);
    })
  }

  handleDressAccessory = ( value ) => {
    this.props.setAvatarAccessoryItem(value);
    this.forceUpdate();
  }

  handleSave = ( value ) => {
    console.log(value);
    if(
      this.props.avatarAccessoryTemp.jewelry.accept &&
      this.props.avatarAccessoryTemp.head.accept &&
      this.props.avatarAccessoryTemp.body.accept &&
      this.props.avatarAccessoryTemp.body_bottom.accept &&
      this.props.avatarAccessoryTemp.footer.accept
    ){
      this.props.setAvatarAccessoryItem(this.props.avatarAccessoryTemp);
      Axios({
        url: URL_SAVE,
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        data:{
          "id": this.props.login.id,
          "avatarAccessory": {
            "jewelry": this.props.avatarAccessoryTemp.jewelry.file,
            "head": this.props.avatarAccessoryTemp.head.file,
            "body": this.props.avatarAccessoryTemp.body.file,
            "body_bottom": this.props.avatarAccessoryTemp.body_bottom.file,
            "footer": this.props.avatarAccessoryTemp.footer.file,
          }
        }
      })
      .then( (res) => {
        if(res.data.success){
          this.props.history.push("/home");
        }
        else
        {

        }
        
      })
      .catch( err => {
        return Promise.reject(err);
      })
      
    }
    else
    {
      this.setState({
        msg:"Necesitas comprar los items para guardar tu avatar"
      })
      this.handleCloseAlert();
    }
  }

  handleClose = ( ) => {
    console.log("close");
    this.props.history.push('/home')
  }

  handleCloseAlert = () => {
    console.log("closeAlert");
    var show = this.state.show;
    this.setState({show:!show});
  }

  render(){
    return (
      <StoreComponent
        name={this.props.login.student}
        avatar={this.props.avatar}
        handleBuyAccessory={this.handleBuyAccessory}
        handleCloseAlert={this.handleCloseAlert}
        handleDressAccessory={this.handleDressAccessory}
        avatarAccessory={this.props.avatarAccessoryTemp}
        store={this.props.store}
        handleClose={this.handleClose}
        handleSave={this.handleSave}
        show={this.state.show}
        msg={this.state.msg}
        width={this.state.width}
        height={this.state.height}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  login: getLogin(state),
  avatar: getAvatar(state),
  store: getStore(state),
  avatarAccessory: getAvatarAccessory(state),
  avatarAccessoryTemp: getAvatarAccessoryTemp(state)
});

export default withRouter( connect( mapStateToProps, { setCount, setAvatarAccessory, setAvatarAccessoryItem,
  setDressAccessory, setAvatarAccessoryTemp } ) (StoreContainer) )