import React from 'react';
import { Container, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormControl, InputLabel, Input, Button, Grid, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import '../../dist/customScroll.css';

const DashboardComponent = props => {
  const {name, count, handleChangeAvatar, avatar, avatarAccessory, handleGoShop} = props;
  return (
    <div>
      <Container className="background" fluid={true}>
        <Row className="title">
          <Col className="" lg={{size:12}} md={{size:12}} xs={{size:12}}>
            <h4 className="intro">Bienvenido {name}</h4>
            <img className="logo" alt="logo" src="./../../image/logo.png" />
          </Col>
        </Row>
        <Row >
          <div style={{height:"50px"}}></div>
          <Col md={{size:12}} xL={{size:8, offset:2}} lg={{size:12}}>
            <Row>
              <Col xl={{size:4}} lg={{size:6}} md={{size:6}} xs={{size:12}}>
                <div className="avatarDiv">
                  <img className="avatar" src={"./../../image/avatars/monitos_"+ avatar +".png"} width="350"/>
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/jewelry/"+ avatarAccessory.jewelry + "_" + avatar + ".png"}  />
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body_bottom/"+ avatarAccessory.body + "_" + avatar + ".png"}  />
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body/"+ avatarAccessory.body_bottom + "_" + avatar + ".png"}  />
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/head/"+ avatarAccessory.head + "_" + avatar + ".png"} />
                </div>
                <div className="buttonDash">
                  <Button className="buttonDashButton" onClick={handleChangeAvatar}>
                    Cambiar avatar
                  </Button>
                  <Button className="buttonDashButton" onClick={handleGoShop} style={{marginLeft:"20px"}}>
                    Tienda
                  </Button>
                </div>
              </Col>
              <Col className="marco" xl={{size:6}} lg={{size:6}} md={{size:6}} xs={{size:12}}>
                <Row style={{margin:"auto"}}>
                  <Col md={{size:12}} lg={{size:8}} style={{margin:"auto"}}>
                    <Row>
                      <Col lg={{size:12}} md={{size:12}} xs={{size:12}}>
                        <img className="cofre_2" src="./../../image/cofre/cofre.png"/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={{size:10, offset:1}}>
                    <CustomScroll heightRelativeToParent="350px">
                      <Col lg={{size:10}} style={{margin:"auto"}}>
                        <Row>
                          <Col lg={{size:12}} md={{size:12}} xs={{size:12}} style={{height:"30px"}}></Col>
                          <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/banana/bananaAmarilla.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['banana_amarilla']}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col  lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/totem/totemsAmarillo.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['totem_amarilla']}</p>
                              </Col>
                            </Row>
                          </Col>
                          
                          
                          <Col lg={{size:12}} md={{size:12}} xs={{size:12}} style={{height:"30px"}}></Col>
                          <Col   lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/banana/bananaAzul.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['banana_azul']}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/totem/totemsAzul.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['totem_azul']}</p>
                              </Col>
                            </Row>
                          </Col>
                          
                          <Col lg={{size:12}} md={{size:12}} xs={{size:12}} style={{height:"30px"}}></Col>
                          <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/banana/bananaNaranja.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['banana_naranja']}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                            <Row className="count" style={{margin: "auto"}}>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                                <img src="./../../image/totem/totemsNaranja.png" height="90px" width="auto"/>
                              </Col>
                              <Col lg={{size:6}} md={{size:6}} xs={{size:6}} style={{display:"flex"}}>
                                <p className="count_number">{count['totem_naranja']}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </CustomScroll>
                  </Col>
                  
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardComponent;